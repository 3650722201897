<template>
    <v-container fluid>
        <v-form @submit.prevent="saveArt">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'art' , 'art_category_id': $route.params.art_category_id}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" md="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2" class="text-center">
                                <make-photo v-model="image" aspect-ratio="1"/>
                            </v-col>
                            <v-col cols="10">
                                <v-subheader class="mb-2 font-weight-medium">
                                    {{ $t('description') }}
                                </v-subheader>
                                <ValidationProvider ref="description"
                                                    rules="min:3|max:65535"
                                                    v-slot="{ errors, valid }">
                                    <tinymce-editor id="description" v-model="description"
                                                    :init="tinymceInit"></tinymce-editor>
                                    <div v-show="!valid" class="mt-2" style="color: red">
                                        {{ errors[0] ? errors[0] : $t('description_is_not_filled_out') }}
                                    </div>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-progress-linear v-show="$vuetify.breakpoint.smAndUp" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-show="$vuetify.breakpoint.smAndUp"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xs" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"
    import MakePhoto from '../components/MakePhoto.vue'
    import TinymceEditor from '@tinymce/tinymce-vue'

    export default {
        name: 'ArtForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            MakePhoto,
            TinymceEditor
        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                progress: 0,
                loading: false,
                tab: 0,
                language: null,
                errors: {},
                id: null,
                image: null,
                exist_translations: {},
                all_translations: true,
                description: null,
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang', 'defaultLogo']),
            languages() {
                return this.listLanguages
            },
            tinymceInit() {
                return this.tinymceInitDefault()
            }
        },
        mounted() {
            this.language = this.languages[this.tab]
            this.checkCreate()
        },
        methods: {
            checkCreate() {
                if (this.$route.name === "art.create") {
                    this.heading = this.$t('art_creation')
                } else {
                    this.heading = this.$t('art_editing')
                    if (this.$route.params.id) {
                        this.getArt()
                    }
                }
            },
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
            async getArt() {
                var _this = this
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/art/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.image = res.body.data.image
                        this.description = res.body.data.description
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_art'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveArt() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }

                if (this.image) {
                    if (this.image.length > 250) {
                        var mimeType = this.getMimeType(this.image)
                        var blob = this.dataURL64toBlob(this.image)
                        if (mimeType && blob) {
                            formData.append('image', blob, mimeType)
                        }
                    } else {
                        formData.append('image', this.image)
                    }
                }
                if (this.description) {
                    formData.append('description', this.description)
                }
                formData.append('art_category_id',this.$route.params.art_category_id)

                if (this.$route.params.id) {
                    // Save
                    await this.$http
                        .put(`admin/art/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('art_has_been_updated'))
                            if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('art_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/art', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('art_has_been_added'))
                            if (res && res.body && res.body.data && res.body.data.id) {
                                this.exist_translations = res.body.data.exist_translations
                                this.all_translations = res.body.data.all_translations
                                this.$router.push({
                                    name: 'art.edit',
                                    params: {
                                        art_category_id: this.$route.params.art_category_id,
                                        id: res.body.data.id
                                    }
                                })
                            } else {
                                this.$router.push({
                                    name: 'art',
                                    art_category_id: this.$route.params.art_category_id,
                                })
                            }
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('art_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
